import { useRouter } from "next/router";
import styled, { Box } from "@xstyled/styled-components";
import Button from "@/UI/Button";
import { FlexBox } from "@/UI/Containers";
import Icon from "@/UI/Icon";
import { CaretLeft } from "@/UI/icons";

const ReturnButton = styled(Button)`
  margin-top: 36px;
  padding: 10px 24px;
  border-radius: 5px;
  color: #fff;
  font-family: "Lufga-Regular";

  svg path {
    transition: all 0.3s ease;
  }

  &:hover {
    svg path {
      stroke: #000;
      transition: all 0.3s ease;
    }
  }
`;

export default function WOF404() {
  const router = useRouter();

  return (
    <FlexBox
      w="100%"
      h="100vh"
      justify="center"
      backgroundImage="url(/assets/backgrounds/404_background.png)"
      backgroundRepeat="no-repeat"
      backgroundSize="cover"
      backgroundPosition="center"
    >
      <FlexBox dir="column">
        <Box
          as="h1"
          m={0}
          fontFamily="Lufga-Medium"
          fontSize="2rem"
          color="#fff"
        >
          Page not found
        </Box>
        <Box
          as="span"
          mt={-12}
          fontFamily="Lufga-Semibold"
          fontSize={{ _: 120, sm_tablet: 240 }}
          color="#fff"
          opacity={0.3}
        >
          404
        </Box>
        <Box
          as="hr"
          w={{ _: "100%", desktop: 580 }}
          mt={{ sm_tablet: -42 }}
          color="#fff"
          borderColor="#fff"
        />
        <ReturnButton
          onClick={() => router.push("/")}
          spaceX={10}
          border="1px solid #707070"
          hoverColor="#000"
          hoverBg="#fff"
        >
          <Icon aria="Back to home" transform="scale(0.8) translateY(5%)">
            <CaretLeft stroke="#fff" />
          </Icon>
          <span>Back to Home</span>
        </ReturnButton>
      </FlexBox>
    </FlexBox>
  );
}
